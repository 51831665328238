<template>
  <!-- 需求定制列表 -->
  <div class="zt-page-content">
    <div class="zt-block">
      <div class="list" style="height: 100%; overflow: auto">
        <div
          style="min-width: 1330px"
          v-for="(item, idx) in list"
          :key="idx"
          class="list-item1"
        >
          <el-row v-if="item.id" class="item-info">
            <el-col class="order-header">
              <el-row type="flex">
                <el-col :span="4">
                  <img
                    :src="
                      imgUrl +
                      item.requirement.gaikuang.productImgs[0] +
                      '!width_250px'
                    "
                    width="180"
                    alt="加载中..."
                    class="image"
                    @click.stop="
                      goto(
                        '/want/demandCustomize/customizeDetail/' +
                          item.id +
                          '?type=1'
                      )
                    "
                  />
                </el-col>
                <el-col :span="20" class="content">
                  <el-row type="flex" justify="space-between">
                    <el-col>
                      <h3>{{ item.requirement.title }}</h3>
                      <div class="detail">
                        <span>交易日期:</span>
                        <span>{{
                          item.createdAt | moment("YYYY-MM-DD HH:mm:ss")
                        }}</span>
                      </div>
                      <div class="detail">
                        <span>发起时间:</span>
                        <span>{{
                          item.tradeOrder.createdAt | moment("YYYY-MM-DD")
                        }}</span>
                      </div>
                      <div class="detail">
                        <span>实际出价:</span>
                        <span class="highlight"
                          >¥{{ item.tradeOrder.ar | priceFilt }}</span
                        >
                        <span>&nbsp;&nbsp;已收款:</span>
                        <span
                          >¥{{ item.tradeOrder.paidAmount | priceFilt }}</span
                        >
                      </div>
                    </el-col>
                    <el-col style="text-align: right">
                      <el-button
                        type="primary"
                        @click="
                          goto(
                            '/want/wantFollow/want/detail/' + item.requirementId
                          )
                        "
                        >查看原需求</el-button
                      >
                      <el-button
                        @click="
                          goto(
                            '/want/demandCustomize/customizeDetail/' +
                              item.id +
                              '?type=1'
                          )
                        "
                        >订单详情</el-button
                      >
                    </el-col>
                  </el-row>
                  <el-row class="company" type="flex" align="middle">
                    <div
                      style="
                        width: 33px;
                        height: 33px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 8px;
                      "
                    >
                      <div
                        v-if="item.sellerComp.logo"
                        class="bg_img"
                        :style="{
                          backgroundImage:
                            'url(' + imgUrl + item.sellerComp.logo + ')',
                        }"
                      ></div>
                      <div
                        v-else
                        class="bg_img"
                        :style="{
                          backgroundImage:
                            'url(' +
                            staticUrl +
                            'design/placeholder/zhanweilogo%402x.jpg!width_250px' +
                            ')',
                        }"
                      ></div>
                    </div>
                    <span>{{ item.sellerComp.name }}</span>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
            <el-col class="order-footer">
              <SignPlan
                :item="item"
                :planType="'gk_order'"
                @refresh="refreshItems"
              ></SignPlan>
            </el-col>
          </el-row>
        </div>
        <div
          v-if="list && list.length == 0"
          class="flex flex-center"
          style="text-align: center; height: 100%; min-height: 400px"
        >
          <div>
            <img
              style="width: 110px"
              :src="staticUrl + '/design/placeholder/meiyougengduo.png'"
              alt
            />
            <div class="no-data">暂无相关数据</div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 7px">
      <el-pagination
        background
        layout="prev, pager, next,total"
        :current-page.sync="queryOpt.page.pageNumber"
        :page-size="queryOpt.page.pageCount"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { IMG_URL_PRE, STATIC_URL_PRE } from "@/config";
import { gaikuangBuyerList, dayangBuyerList } from "@/service/want";
import SignPlan from "@/components/tradeOrder/Buyer.vue";
export default {
  components: {
    SignPlan,
  },
  props: ["query"],
  watch: {
    queryOpt: {
      handler() {
        this.handlerChange();
      },
      deep: true,
    },
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      staticUrl: STATIC_URL_PRE,
      tabList: [
        { name: "已发布" },
        { name: "已达成" },
        { name: "已关闭" },
        { name: "草稿" },
      ],
      tabIndex: 0,
      dateRange: [],
      queryOpt: {
        demandType: 0,
        orderState: -1,
        createdAt: {},
        page: {
          pageNumber: 1,
          pageCount: 10,
        },
      },
      searchForm: {
        where: {
          states: [0, 2, 4, 5, 6],
          giveDate: {
            start: null,
            end: null,
          },
          types: [],
        },
        page: {
          pageNumber: 1,
          pageCount: 10,
        },
      },
      keywords: "",
      total: 0,
      list: [],
    };
  },
  created() {
    if (this.query.key) {
      this.searchForm.key = this.query.key;
    }
    if (this.query.dateRange && this.query.dateRange.length) {
      this.searchForm.where.giveDate.start = new Date(
        this.query.dateRange[0]
      ).getTime();
      this.searchForm.where.giveDate.end = new Date(
        this.query.dateRange[1]
      ).getTime();
    } else {
      this.searchForm.where.giveDate.start = null;
      this.searchForm.where.giveDate.end = null;
    }
    this.searchForm.where.states =
      this.query.orderState === -1 ? [0, 2, 4, 5, 6] : [this.query.orderState];
    if (this.query.page) {
      this.searchForm.page.pageNumber = this.query.page;
    }
    if (this.query.pagesize) {
      this.searchForm.page.pageCount = this.query.pagesize;
    }
    this.refreshItems();
    this.$nextTick(() => {
      this.pageShow = true;
    });
    this.refreshItems();
  },
  methods: {
    handlerChange() {
      this.searchForm.page.pageNumber = this.queryOpt.page.pageNumber;
      this.refreshItems();
    },
    goto(link) {
      this.$router.push(link);
    },
    refreshItems() {
      gaikuangBuyerList(this.searchForm)
        .then((rst) => {
          if (rst.rows && rst.rows.length) {
            this.total = rst.count;
            this.list = rst.rows;
            // 签约状态
            if (this.list && this.list.length > 0) {
              this.list.forEach((e, idx) => {
                if (e.tradeOrder) {
                  e.trans_stat = e.tradeOrder.state;
                  e.contract_no = e.tradeOrder.contract_no;
                }
              });
            }
          } else {
            this.total = 0;
            this.list = [];
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
  },
};
</script>
<style scoped src="../style.css"></style>
<style scoped>
.block {
  margin: 20px 20px;
  /* background: transparent; */
}
/*----选项卡----*/
.tab_menu {
  min-width: 680px;
  /* background: #fff; */
  padding: 10px 0;
  margin: 20px 10px;
}
.tab_menu .menu_item {
  height: 40px;
  line-height: 40px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 14px;
  color: #808080;
}
.tab_menu .menu_item.cur .menu_item_button {
  color: #4d4d4d;
  background: linear-gradient(
    90deg,
    rgba(131, 98, 228, 1) 0%,
    rgba(80, 116, 238, 1) 100%
  );
  box-shadow: 0px 2px 4px 0px rgba(107, 132, 219, 1);
  border: none;
  color: #fff;
}
.menu_item_button {
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  border: 1px solid rgba(179, 179, 179, 1);
  font-size: 14px;
  color: #808080;
}
.list > .list-item {
  padding: 20px;
  background: #fff;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid rgba(230, 230, 230, 1);
  font-size: 14px;
  color: #4d4d4d;
}
.list > .list-item:last-child {
  margin-bottom: 0;
}
.list > .list-item > div {
  min-width: 180px;
  min-height: 120px;
  padding: 0 30px;
  border-right: 1px solid #e6e6e6;
}
.list > .list-item > div:first-child {
  padding-left: 0;
}
.list > .list-item > div:last-child {
  border-right: none;
}
.list > .list-item .list-item-tr > div:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
  color: #3b3b3b;
  line-height: 25px;
  margin-top: 10px;
  word-wrap: break-word;
}
.pageCount {
  margin: 0 20px 10px 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #4d4d4d;
}
.list-item-tr >>> button {
  margin: 5px;
}
.alert-red {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #f66f6a;
  border-radius: 50%;
  margin-left: 5px;
}
</style>
<style lang="less" scoped>
.list-item1 {
  padding: 20px;

  .item-info {
    border: 1px solid #cccccc;

    .order-header {
      padding: 20px;
      border-bottom: 1px solid #cccccc;

      .image {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .order-footer {
      padding: 20px;
    }
  }

  .detail {
    font-size: 14px;
    font-weight: 400;
    color: #808080;
    height: 33px;
    line-height: 33px;

    .highlight {
      font-size: 24px;
      font-weight: 600;
      color: #f66f6a;
    }
  }

  .company {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
  }
}
</style>